<template>
  <div>
    <Hero />
    <Teams class="my-5" />
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {
    Hero: () => import("../components/Hero1.vue"),
    Teams: () => import("../components/TeamSearch.vue"),
  },
  mounted() {
    this.$store.dispatch("teams/getAllTeams");
  },
};
</script>

<style></style>
